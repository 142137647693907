<template>
  <page-header-wrapper :showInModal="showInModal">
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper" v-if="!showInModal">
        <a-form layout="inline">
          <a-row :gutter="48">
            <!-- <a-col :md="8" :sm="24">
              <a-form-item label="提现流水号" prop="withdrawalNo">
                <a-input v-model="queryParam.withdrawalNo" placeholder="请输入提现流水号" allow-clear />
              </a-form-item>
            </a-col> -->
            <a-col :md="8" :sm="24">
              <a-form-item label="用户账号" prop="userId">
                <a-select
                  show-search
                  allowClear
                  placeholder="请输入用户账号模糊查找"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="handleUserNameSearch"
                  @change="handleUserNameChange"
                  :loading="usloading"
                  v-model="queryParam.userId"
                >
                  <a-select-option v-for="d in usersList" :key="d.userId" :value="d.userId">
                    {{ d.userName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="银行卡号" prop="bankNo">
                <a-select
                  show-search
                  allowClear
                  placeholder="请输入银行卡号模糊查找"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="handleBankNoSearch"
                  @change="handleBankNoChange"
                  :loading="bkloading"
                  v-model="queryParam.bankNo"
                >
                  <a-select-option v-for="d in bankNoList" :key="d.bankNo" :value="d.bankNo">
                    {{ d.bankNo }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <!-- <a-col :md="8" :sm="24">
                <a-form-item label="提现姓名" prop="username">
                  <a-input v-model="queryParam.username" placeholder="请输入提现姓名" allow-clear />
                </a-form-item>
              </a-col> -->

              <!-- <a-col :md="8" :sm="24">
                <a-form-item label="提现开户行" prop="bankName">
                  <a-input v-model="queryParam.bankName" placeholder="请输入提现开户行" allow-clear />
                </a-form-item>
              </a-col> -->
              <!-- <a-col :md="8" :sm="24">
                <a-form-item label="提现金额" prop="amount">
                  <a-input v-model="queryParam.amount" placeholder="请输入提现金额" allow-clear />
                </a-form-item>
              </a-col> -->
              <a-col :md="8" :sm="24">
                <a-form-item label="提现状态" prop="withdrawalStatus">
                  <a-select
                    placeholder="请选择提现状态"
                    v-model="queryParam.withdrawalStatus"
                    style="width: 100%"
                    allow-clear
                  >
                    <a-select-option
                      v-for="(item, index) in withdrawalStatusOptions"
                      :key="index"
                      :value="item.dictValue"
                    >{{ item.dictLabel }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <!-- <a-col :md="8" :sm="24">
                <a-form-item label="提现操作人员" prop="backendUserId">
                  <a-input v-model="queryParam.backendUserId" placeholder="请输入提现操作人员 后台用户id" allow-clear />
                </a-form-item>
              </a-col> -->
              <!-- <a-col :md="8" :sm="24">
                <a-form-item label="是否删除" prop="status">
                  <a-select placeholder="请选择是否删除" v-model="queryParam.status" style="width: 100%" allow-clear>
                    <a-select-option v-for="(item, index) in statusOptions" :key="index" :value="item.dictValue">{{
                      item.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col> -->
              <a-col :md="8" :sm="24">
                <a-form-item label="申请时间">
                  <a-range-picker
                    style="width: 100%"
                    v-model="daterangeCreateTime"
                    valueFormat="YYYY-MM-DD"
                    format="YYYY-MM-DD"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1" v-if="!showInModal">
        <div class="table-operations">
          <a-button type="primary" :disabled="multiple" @click="updateWithdrawalStatus">
            <a-icon type="plus" />批量确认已转账
          </a-button>
          <a-button
            type="primary"
            @click="$refs.createForm.handleAdd()"
            v-hasPermi="['tenant:recyclable:withdrawalRecord:add']"
          >
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['tenant:recyclable:withdrawalRecord:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['tenant:recyclable:withdrawalRecord:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:recyclable:withdrawalRecord:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{ float: 'right' }"
          icon="reload"
          @click="getList"
        /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
        :statusOptions="statusOptions"
        :withdrawalStatusOptions="withdrawalStatusOptions"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
          getCheckboxProps: getCheckboxProps
        }"
        :pagination="false"
      >
        <a-span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </a-span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:recyclable:withdrawalRecord:list']" />
          <a
            @click="$refs.createForm.handleUpdate(record, undefined)"
            v-hasPermi="['tenant:recyclable:withdrawalRecord:list']"
          >
            <a-icon type="info-circle" />查看
          </a>
          <a-divider type="vertical" v-if="record.withdrawalStatus === 1" />
          <a v-if="record.withdrawalStatus === 1" @click="updateWithdrawalStatus(record)">
            <a-icon type="check" />确认转账</a
          >
          <a-divider type="vertical" v-hasPermi="['tenant:recyclable:withdrawalRecord:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:recyclable:withdrawalRecord:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {
  listWithdrawalRecord,
  delWithdrawalRecord,
  exportWithdrawalRecord,
  updateWithdrawalStatus,
  searchBank
} from '@/api/recyclable/withdrawalRecord'
import CreateForm from './modules/CreateForm'
import { listWorkers } from '@/api/recyclable/users'
import debounce from 'lodash/debounce'
import { statusOptions, withdrawalStatusOptions } from './modules/options'

export default {
  name: 'WithdrawalRecord',
  components: {
    CreateForm
  },
  props: {
    showInModal: {
      type: Boolean,
      default: false
    },
    userInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    this.handleUserNameSearch = debounce(this.handleUserNameSearch, 500)
    this.handleBankNoSearch = debounce(this.handleBankNoSearch, 500)
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      bankNoList: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      // 申请时间范围
      daterangeCreateTime: [],
      loading: false,
      usloading: false,
      bkloading: false,
      total: 0,
      // 查询参数
      queryParam: {
        withdrawalNo: null,
        userId: undefined,
        username: null,
        bankNo: undefined,
        bankName: null,
        amount: null,
        withdrawalStatus: '',
        backendUserId: null,
        status: null,
        params: {
          startTime: null,
          endTime: null
        },
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '提现流水号',
          dataIndex: 'withdrawalNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户账号',
          dataIndex: 'createBy',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '银行卡姓名',
          dataIndex: 'username',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '银行卡号',
          dataIndex: 'bankNo',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '提现开户行',
        //   dataIndex: 'bankName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '提现金额',
          dataIndex: 'amount',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '提现状态',
          dataIndex: 'withdrawalStatus',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return this.optionsFormat(this.withdrawalStatusOptions, record.withdrawalStatus)
          }
        },
        {
          title: '提现操作人员',
          dataIndex: 'backendUserName',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return record.backendUserName || '--'
          }
        },
        // {
        //   title: '是否删除',
        //   dataIndex: 'status',
        //   ellipsis: true,
        //   align: 'center',
        //   customRender: (text, record, index) => {
        //     return this.optionsFormat(this.statusOptions, record.status)
        //   }
        // },
        {
          title: '申请时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      statusOptions,
      withdrawalStatusOptions
    }
  },
  filters: {},
  async created() {
    if (this.userInfo && this.userInfo.userName) {
      this.queryParam.userName = this.userInfo.userName
      await this.getListUsers(this.queryParam.userName)
      this.queryParam.userId = this.userInfo.userId && Number(this.userInfo.userId)
      this.queryParam.withdrawalNo = this.userInfo.accountNo
    }
    if (this.showInModal) {
      this.queryParam.withdrawalStatus = undefined
    }
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    getListUsers(name) {
      return new Promise((resolve, reject) => {
        listWorkers({
          userName: name,
          type: '2'
        }).then((response) => {
          this.usersList = response.data
          this.usloading = false
          resolve()
        })
      })
    },
    handleUserNameSearch(value) {
      console.log('handleUserNameSearch', value)
      this.usloading = true
      this.getListUsers(value)
    },
    handleUserNameChange(value) {
      console.log('handleUserNameChange', value)
      this.form.userId = value
    },
    handleBankNoSearch(value) {
      console.log('handleUserNameSearch', value)
      this.bkloading = true
      this.searchBank(value)
    },
    handleBankNoChange(value) {
      console.log('handleUserNameChange', value)
      this.queryParam.bankNo = value
    },
    /** 查询提现记录列表 */
    getList() {
      this.queryParam.params = {}
      if (
        this.daterangeCreateTime !== null &&
        this.daterangeCreateTime !== '' &&
        this.daterangeCreateTime.length !== 0
      ) {
        this.queryParam.params['startTime'] = this.daterangeCreateTime[0]
        this.queryParam.params['endTime'] = this.daterangeCreateTime[1]
      }
      this.loading = true
      listWithdrawalRecord(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeCreateTime = []
      this.queryParam = {
        withdrawalNo: undefined,
        userId: undefined,
        username: undefined,
        bankNo: undefined,
        bankName: undefined,
        amount: undefined,
        withdrawalStatus: '1',
        backendUserId: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    getCheckboxProps(record) {
      return {
        props: {
          disabled: record.withdrawalStatus === 2 // Column configuration not to be checked
        }
      }
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delWithdrawalRecord(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportWithdrawalRecord(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    optionsFormat(options, value) {
      return this.selectDictLabel(options, value)
    },
    updateWithdrawalStatus(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '是否确认已转账?',
        content: '此操作将把当前选中提现记录状态更改为已到账',
        onOk() {
          return updateWithdrawalStatus(ids).then((response) => {
            that.onSelectChange([], [])
            that.$message.success('确认成功', 3)
            that.getList()
          })
        },
        onCancel() {}
      })
    },
    searchBank(bankNo) {
      searchBank({
        bankNo
      }).then((res) => {
        this.bankNoList = res.data
        this.bkloading = false
      })
    }
  }
}
</script>
